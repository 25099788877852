<template>
  <div class="steps-progress" data-t="steps-progress">
    <div
      v-for="step in stepsProgress.steps"
      :key="step.id"
      class="step"
      data-t="rolling-step"
      :class="step.status"
    />
  </div>
</template>

<script setup lang="ts">
import type { StepRolling } from '../../types'

interface Props {
  stepsProgress?: {
    steps: StepRolling[]
    inProgressStepProgress: string
  }
}

const {
  stepsProgress = {
    steps: [],
    inProgressStepProgress: '0',
  },
} = defineProps<Props>()

const currentStepProgressPercents = computed(
  () => `${stepsProgress.inProgressStepProgress}%`,
)
</script>

<style scoped>
.steps-progress {
  display: flex;
  gap: var(--spacing-025);
  align-items: center;

  .step {
    position: relative;

    flex: 1 1 0;

    min-width: 0;
    height: 6px;

    background-color: var(--background-ghost-tertiary);
    border-radius: var(--border-radius-100);

    &.rolled,
    &.claimed {
      background-color: var(--background-success);

      /* stylelint-disable-next-line */
      & + .inProgress::after {
        content: '';

        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: v-bind(currentStepProgressPercents);

        background-color: var(--background-success);
        border-radius: var(--border-radius-100);
      }
    }
    /* stylelint-disable-next-line */
    &.inProgress:first-child::after {
      content: '';

      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;

      width: v-bind(currentStepProgressPercents);

      background-color: var(--background-success);
      border-radius: var(--border-radius-100);
    }
  }
}
</style>
