import { sortBy } from '@st/utils'
import Decimal from '@st/decimal'
import type { StepRolling, Rolling } from '../types'

interface UseBonusStepRollingParams {
  stepRollingData: StepRolling[]
  rollingData: Rolling | null
  bonusAmount: string
}

interface UseBonusRollingReturn {
  steps: ComputedRef<StepRolling[]>
  unlockedAmount: ComputedRef<string>
  untilNextClaimAmount: ComputedRef<string>
  inProgressStepProgress: ComputedRef<string>
  canPartClaim: ComputedRef<boolean>
  partClaim: () => Promise<unknown>
  partClaimStatus: Ref<string>
  isEnabledStepsRolling: ComputedRef<boolean>
  stepsProgress: ComputedRef<
    | {
        steps: StepRolling[]
        inProgressStepProgress: string
      }
    | undefined
  >
}

export function useBonusStepRolling(
  params: ComputedRef<UseBonusStepRollingParams>,
): UseBonusRollingReturn {
  const steps = computed(() =>
    sortBy(params.value.stepRollingData, (step) => [step.id]),
  )
  const rolledSteps = computed(() =>
    steps.value.filter((step) => step.status === 'rolled'),
  )
  const claimedSteps = computed(() =>
    steps.value.filter((step) => step.status === 'claimed'),
  )
  const firstInProgressStep = computed(() =>
    steps.value.find((step) => step.status === 'inProgress'),
  )

  const isEnabledStepsRolling = computed(
    () => steps.value.length > 1 && !!firstInProgressStep.value,
  )

  const fullRollingAmount = computed(
    () => params.value.rollingData?.fullAmount ?? '',
  )
  const currentRollingAmount = computed(
    () => params.value.rollingData?.currentAmount ?? '',
  )
  const rollingProgress = computed(() => {
    if (!params.value.rollingData) return ''

    const result = new Decimal(params.value.rollingData.currentAmount)
      .dividedBy(params.value.rollingData.fullAmount)
      .mul(100)
      .toFixed(2)

    return Decimal.min(result, 100).toString()
  })

  /**
   * Разблокированная сумма бонуса, которую можно забрать
   * отображается, если есть шаги со статусом 'rolled'
   */
  const unlockedAmount = computed(() => {
    if (rolledSteps.value.length === 0) return ''

    const currentMultiplier = rolledSteps.value.reduce(
      (sum, step) => sum.plus(step.multiplier),
      new Decimal(0),
    )

    return new Decimal(params.value.bonusAmount)
      .mul(currentMultiplier)
      .toFixed(2)
      .toString()
  })

  /**
   * Сумма которую необходимо обкатать до следующего начисления
   * отображается, если нет шагов со статусом rolled
   */
  const untilNextClaimAmount = computed(() => {
    if (!fullRollingAmount.value) return ''
    if (rolledSteps.value.length) return ''

    if (!firstInProgressStep.value) return ''

    const rollingPercentBySteps = [
      ...claimedSteps.value,
      firstInProgressStep.value,
    ].reduce((sum, step) => sum.plus(step.multiplier), new Decimal(0))

    /**
     * (rollingPercentBySteps - currentRollingAmount / fullRollingAmount) * fullRollingAmount
     */
    return rollingPercentBySteps
      .minus(
        new Decimal(currentRollingAmount.value).dividedBy(
          fullRollingAmount.value,
        ),
      )
      .mul(fullRollingAmount.value)
      .toString()
  })

  const inProgressStepProgress = computed(() => {
    if (!rollingProgress.value || !firstInProgressStep.value) return ''

    /**
     * Сумма процентов всех шагов, которые уже обкатаны или забраны
     */
    const finishedStepsPercents = [
      ...claimedSteps.value,
      ...rolledSteps.value,
    ].reduce((sum, step) => sum.plus(step.multiplier), new Decimal(0))

    /**
     * Процент текущего шага от общего прогресса
     * (в сотых по аналогии с step.multiplier)
     */
    const currentStepProgress = new Decimal(rollingProgress.value)
      .dividedBy(100)
      .minus(finishedStepsPercents)

    const result = new Decimal(currentStepProgress)
      .dividedBy(firstInProgressStep.value.multiplier)
      .mul(100)
      .toFixed(2)
      .toString()

    return Decimal.min(result, 100).toString()
  })

  const canPartClaim = computed(() => {
    if (params.value.rollingData?.status === 'paused') return false

    return !!unlockedAmount.value
  })

  const stepsProgress = computed(() => {
    if (!isEnabledStepsRolling.value) return undefined

    return {
      steps: steps.value,
      inProgressStepProgress: inProgressStepProgress.value,
    }
  })

  const { execute: partClaim, status: partClaimStatus } = useStFetch(
    '/user-bonus-rolling/claim',
    {
      method: 'post',
      body: computed(() => ({
        userBonusRollingId: params.value.rollingData?.id ?? 0,
      })),
      immediate: false,
      watch: false,
    },
  )

  return {
    steps,
    unlockedAmount,
    untilNextClaimAmount,
    inProgressStepProgress,
    canPartClaim,
    partClaim,
    partClaimStatus,
    isEnabledStepsRolling,
    stepsProgress,
  }
}
